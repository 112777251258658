<template>
<div>
    <v-card flat style="background-color: var(--v-background-base) !important">
        <v-toolbar color="greyBase" dark extended flat extension-height="55">
            <v-toolbar-title>
                <b class="mr-3">BusPro</b>
            </v-toolbar-title>
        </v-toolbar>

        <v-card class="mx-auto" :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'" :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }">
            <v-card-text :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }">
                <v-tabs v-model="activeTab" height="30px">
                    <v-tab href="#exceptions" @click="activeTab != 'exceptions' ? $router.push({ hash: '#exceptions' }) : ''"> Exceptions </v-tab>
                    <v-tab href="#adminCountries" @click="activeTab != 'adminCountries' ? $router.push({ hash: '#adminCountries' }) : ''"> Countries </v-tab>
                    <v-tab href="#finance" @click="activeTab != 'finance' ? $router.push({ hash: '#finance' }) : ''"> Finance </v-tab>
                    <v-tab href="#notifications" @click="activeTab != 'notifications' ? $router.push({ hash: '#notifications' }) : ''"> Notification Templates </v-tab>
                    <v-tab href="#adminOrganisations" @click="activeTab != 'adminOrganisations' ? $router.push({ hash: '#adminOrganisations' }) : ''"> Organisations </v-tab>
                    <v-tab href="#adminProduct" @click="activeTab != 'adminProduct' ? $router.push({ hash: '#adminProduct' }) : ''"> Product Management </v-tab>
                    <v-tab href="#regimeManagement" @click="activeTab != 'regimeManagement' ? $router.push({ hash: '#regimeManagement' }) : ''"> Regime Management </v-tab>
                    <v-tab href="#pointOfInterest" @click="activeTab != 'pointOfInterest' ? $router.push({ hash: '#pointOfInterest' }) : ''"> POI's</v-tab>
                    <v-tab href="#vesselManagement" @click="activeTab != 'vesselManagement' ? $router.push({ hash: '#vesselManagement' }) : ''"> Vessel Management </v-tab>
                    <v-tab href="#shipmentManagement" @click="activeTab != 'shipmentManagement' ? $router.push({ hash: '#shipmentManagement' }) : ''"> Shipment Management </v-tab>
                    <v-tab href="#news" @click="activeTab != 'news' ? $router.push({ hash: '#news' }) : ''"> News </v-tab>
                    <!-- <v-tab href="#queries" @click = "activeTab != 'queries' ? $router.push({ hash: '#queries' }) : ''"> Queries </v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="activeTab" class="my-0 py-0" style="background: var(--v-component-base) !important; ">
                    <v-tab-item value="exceptions">
                        <Exceptions />
                    </v-tab-item>
                    <v-tab-item value="adminCountries">
                        <Country />
                    </v-tab-item>
                    <v-tab-item value="finance">
                        <Finance />
                    </v-tab-item>
                    <v-tab-item value="notifications">
                        <NotificationTemplate />
                    </v-tab-item>
                    <v-tab-item value="adminOrganisations">
                        <AdminOrganisation />
                    </v-tab-item>
                    <v-tab-item value="adminProduct">
                        <ProductManagement />
                    </v-tab-item>
                    <v-tab-item value="regimeManagement">
                        <regimeManagement />
                    </v-tab-item>
                    <v-tab-item value="pointOfInterest">
                        <PointOfInterest />
                    </v-tab-item>
                    <v-tab-item value="vesselManagement">
                        <VesselManagement />
                    </v-tab-item>
                    <v-tab-item value="shipmentManagement">
                        <ShipmentUnlink />
                    </v-tab-item>
                    <v-tab-item value="news">
                        <News />
                    </v-tab-item>
                    <v-tab-item value="queries">
                        <Queries />
                    </v-tab-item>
                </v-tabs-items>

            </v-card-text>
        </v-card>
    </v-card>
</div>
</template>

<script>
import AdminOrganisation from "../components/Admin/Organisation.vue";
import Country from "../components/Region/Country.vue";
import Exceptions from '../components/Admin/Exceptions.vue'
import Finance from "../components/Admin/Finance.vue";
import NotificationTemplate from "../components/Admin/NotificationTemplate.vue";
import PointOfInterest from "../components/Trucks/POI.vue";
import ShipmentUnlink from "../components/Admin/ShipmentUnlink.vue";
import ProductManagement from "../components/Admin/ProductManagement.vue";
// import Region from "../components/Region/Region.vue";
import regimeManagement from "../components/Admin/RegimeManagement.vue"
import VesselManagement from "../components/Admin/VesselManagement.vue";
import News from '../components/Admin/NewsAdmin.vue'
export default {
    components: {
        AdminOrganisation,
        Country,
        Exceptions,
        Finance,
        // DocumentType,
        NotificationTemplate,
        PointOfInterest,
        ProductManagement,
        // Region,
        // sensorPlugin,
        regimeManagement,
        ShipmentUnlink,
        VesselManagement,
        News
    },
    data: () => ({
        activeTab: 'adminCountries',
        loaded: true,
    }),
    mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#adminCountries'
            })
        }
    }
};
</script>

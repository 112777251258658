<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-row justify="end">
                    <v-col cols="12" sm="6" md="4">
                        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="search" clearable
                            placeholder="Search">
                            <template slot="prepend">
                                <el-button @click="addItem()"><i class="el-icon-plus"></i>
                                </el-button>
                            </template>
                            <template slot="append">
                                <v-badge :content="computeFilterCount" :value="computeFilterCount > 0" color="primary"
                                    offset-y="10">
                                    <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                                        <v-icon small>filter_alt</v-icon>
                                    </el-button>
                                </v-badge>
                            </template>
                        </el-input>
                    </v-col>
                </v-row>
                <v-data-table dense :items="filteredChargeItems" height="70vh" fixed-header :headers="headers"
                    :search="search" item-key="id" :key="refreshKey" disable-pagination hide-default-footer
                    :loading="loadingChargeItems">
                    <template v-slot:[`item.action`]="{ item }">
                        <v-row justify="center">
                            <v-btn icon small @click="editItem(item)"><v-icon small>edit</v-icon></v-btn>
                            <v-btn icon small color="red" @click="deleteItem(item)"><v-icon small>delete</v-icon></v-btn>
                        </v-row>
                    </template>
                    <!-- <template v-slot:[`item.baseType`]="{ item }">
                      <span>{{ formatBaseType(item.baseType) }}</span>
                    </template> -->

                    <template v-slot:[`item.importExport`]="{ item }">
                        <v-tooltip top v-if="item.isImport">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="green"><v-icon
                                        small>arrow_downward</v-icon></v-btn>
                            </template>
                            <span>Import</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isExport">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="deep-orange"><v-icon
                                        small>arrow_upward</v-icon></v-btn>
                            </template>
                            <span>Export</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.chargeLocation`]="{ item }">
                        <v-tooltip top v-if="item.isOrigin">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                        small>location_on</v-icon></v-btn>
                            </template>
                            <span>Origin</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isFreight">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                        small>local_shipping</v-icon></v-btn>
                            </template>
                            <span>Freight</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isDestination">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="blue-grey"><v-icon
                                        small>where_to_vote</v-icon></v-btn>
                            </template>
                            <span>Destination</span>
                        </v-tooltip>

                        <!-- <v-chip v-if="item.isOrigin" small color="blue-grey"><v-icon left
                                small>location_on</v-icon>Origin</v-chip>
                        <v-chip v-if="item.isFreight" class="ml-1" small color="blue-grey darken-3"><v-icon left
                                small>local_shipping</v-icon>Freight</v-chip>
                        <v-chip v-if="item.isDestination" class="ml-1" small color="blue-grey darken-5"><v-icon left
                                small>location_on</v-icon>Destination</v-chip> -->
                    </template>

                    <template v-slot:[`item.mode`]="{ item }">
                        <v-tooltip top v-if="item.isSea">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="blue"><v-icon
                                        small>directions_boat</v-icon></v-btn>
                            </template>
                            <span>Sea</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isAir">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="teal"><v-icon
                                        small>flight</v-icon></v-btn>
                            </template>
                            <span>Air</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isRail">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="indigo"><v-icon
                                        small>train</v-icon></v-btn>
                            </template>
                            <span>Rail</span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.isRoad">
                            <template v-slot:activator="{ on }">
                                <v-btn icon outlined small class="mx-1" v-on="on" color="purple"><v-icon
                                        small>local_shipping</v-icon></v-btn>
                            </template>
                            <span>Road</span>
                        </v-tooltip>
                    </template>


                    <template v-slot:[`item.loadType`]="{ item }">
                        <v-tooltip top v-if="item.fullContainerLoad">
                            <template v-slot:activator="{ on }">
                                <v-chip outlined small class="mx-1" v-on="on" color="cyan darken-2">FL</v-chip>
                            </template>
                            <span>Full Load </span>
                        </v-tooltip>

                        <v-tooltip top v-if="item.lessContainerLoad">
                            <template v-slot:activator="{ on }">
                                <v-chip outlined small class="mx-1" v-on="on" color="pink accent-2">LL</v-chip>
                            </template>
                            <span>Less than Load</span>
                        </v-tooltip>
                        <!-- <v-chip v-if="item.fullContainerLoad" outlined small color="cyan darken-2">FL</v-chip>
                        <v-chip v-if="item.lessContainerLoad" outlined class="ml-1" small color="pink accent-2">LL</v-chip> -->
                    </template>

                    <template v-slot:[`item.region`]="{ item }">
                        <!-- <v-tooltip top v-if="item.allOriginRegion">
                            <template v-slot:activator="{ on }">
                                <v-btn color="green" outlined small class="mx-1" v-on="on" icon><v-icon
                                        small>check</v-icon></v-btn>
                            </template>
                            <span>All Regions </span>
                        </v-tooltip> -->
                        <v-tooltip top v-if="!item.allOriginRegion &&formattedRegions('Origin', item)">
                            <template v-slot:activator="{ on }">
                                <v-btn color="green" outlined small class="mx-1" v-on="on" icon>{{ formattedRegions('Origin',
                                    item) }}</v-btn>
                            </template>
                            <span>{{ formattedRegions('Origin', item) }} Origin Regions </span>
                        </v-tooltip>

                        <v-tooltip top v-if="!item.allDestinationRegion && formattedRegions('Destination', item)">
                            <template v-slot:activator="{ on }">
                                <v-btn color="deep-orange" outlined small class="mx-1" v-on="on" icon>{{ formattedRegions('Destination',
                                    item) }}</v-btn>
                            </template>
                            <span>{{ formattedRegions('Destination', item) }} Destination Regions </span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.country`]="{ item }">
                        <v-tooltip top v-if="!item.allOriginCountry && formattedCountries('Origin', item)">
                            <template v-slot:activator="{ on }">
                                <v-btn color="green" outlined small class="mx-1" v-on="on" icon>{{ formattedCountries('Origin',
                                    item) }}</v-btn>
                            </template>
                            <span>{{ formattedCountries('Origin', item) }} Origin Countries </span>
                        </v-tooltip>
                        <v-tooltip top v-if="!item.allDestinationCountry && formattedCountries('Destination', item)">
                            <template v-slot:activator="{ on }">
                                <v-btn color="deep-orange" outlined small class="mx-1" v-on="on" icon>{{ formattedCountries('Destination',
                                    item) }}</v-btn>
                            </template>
                            <span>{{ formattedCountries('Destination', item) }} Destination Countries </span>
                        </v-tooltip>
                    </template>

                    <template v-slot:[`item.isImport`]="{ item }">
                        <v-icon v-if="item.isImport" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isExport`]="{ item }">
                        <v-icon v-if="item.isExport" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isOrigin`]="{ item }">
                        <v-icon v-if="item.isOrigin" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isFreight`]="{ item }">
                        <v-icon v-if="item.isFreight" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isDestination`]="{ item }">
                        <v-icon v-if="item.isDestination" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isSea`]="{ item }">
                        <v-icon v-if="item.isSea" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isAir`]="{ item }">
                        <v-icon v-if="item.isAir" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isRail`]="{ item }">
                        <v-icon v-if="item.isRail" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                    <template v-slot:[`item.isRoad`]="{ item }">
                        <v-icon v-if="item.isRoad" color="green">check_circle_outline</v-icon>
                        <v-icon v-else color="red"> cancel</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <v-dialog v-model="modal" width="900px" persistent :fullscreen="$vuetify.breakpoint.mobile">
            <v-card v-if="item && item.modalType">
                <v-toolbar flat color="transparent" class="my-0 py-0">
                    <v-toolbar-title>
                        {{ item.modalType }} Charge Item
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn :key="validCodeKey" :disabled="!validCode() || (!item.name || !item.finChargeCategoryId)" text icon color="primary"
                        @click="saveChargeItem" :loading="savingChargeItem"><v-icon>save</v-icon></v-btn>
                    <v-btn text @click="modal = false, item = {}">X</v-btn>
                </v-toolbar>
                <v-card-text class="mt-0 pt-0">
                    <v-tabs v-model="chargeTab">
                        <v-tabs-slider color="primary"></v-tabs-slider>
                        <v-tab>Info</v-tab>
                        <v-tab>Origin Countries</v-tab>
                        <v-tab>Destination Countries</v-tab>

                    </v-tabs>
                </v-card-text>

                <v-card-text v-if="chargeTab == 0">
                    <v-row>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :items="chargeCategories" v-model="item.finChargeCategoryId" :key="editKey"
                                :append-outer-icon="item.finChargeCategoryId ? 'edit' : ''"
                                @click:append-outer="editCategory(item.finChargeCategoryId)"
                                @change="item.finChargeSubCategoryId = null, editKey++" label="Category*"
                                item-text="category" item-value="id" outlined dense>
                                <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item style="height: 30px" @click="addCategory()">
                                        <v-list-item-content class="text-center">
                                            <v-list-item-title class="text-center">
                                                <v-btn color="primary" small text>Add Category</v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :disabled="!item.finChargeCategoryId" :items="chargeSubCategories" :key="editKey"
                                :append-outer-icon="item.finChargeSubCategoryId ? 'edit' : ''"
                                @click:append-outer="editSubcategory(item.finChargeSubCategoryId)"
                                v-model="item.finChargeSubCategoryId" label="Subcategory" item-text="subCategory"
                                @change="editKey++" item-value="id" outlined dense>
                                <template v-slot:append-item>
                                    <v-divider class="mb-2"></v-divider>
                                    <v-list-item style="height: 30px" @click="addSubCategory()">
                                        <v-list-item-content class="text-center">
                                            <v-list-item-title class="text-center">
                                                <v-btn small color="primary" text>Add Subcategory</v-btn>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>

                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-text-field :disabled="item.id != null && item.id != undefined" label="Code"
                                :rules="codeRules" v-model="item.code" clearable outlined dense @change="validCodeKey ++"></v-text-field>
                            <v-text-field label="Name*" v-model="item.name" clearable outlined dense></v-text-field>
                            <!-- <v-select label="Base Type*" outlined dense clearable :items="baseTypes" item-text="name"
                                item-value="value" v-model="item.baseType"></v-select> -->
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-textarea rows="4" label="Description" v-model="item.description" clearable outlined
                                dense></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <!-- <v-divider></v-divider> -->
                            <v-subheader class="my-0 py-0"
                                style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Shipment
                                Type</v-subheader>

                            <!-- <v-divider></v-divider> -->
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Import Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                        Apply this charge item to import shipments
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isImport"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Export Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 12px">
                                        Apply this charge item to export shipments
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isExport"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Load
                                Type</v-subheader>

                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Less than Load (LL)
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.lessContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Full Load (FL)
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.fullContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader
                                style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Charge
                                Type</v-subheader>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Origin Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item is part of Landside charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isOrigin"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Freight Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item as part of freight charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isFreight"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="4" class="my-0 py-0">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Destination Charge
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap" style="font-size: 12px">
                                        Apply this charge item as part of destination charges
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="item.isDestination"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" class="my-0 py-0">
                            <v-divider></v-divider>
                            <v-subheader style="font-size: 16px; font-weight: bold; background: var(--v-greyBase-base)">Mode
                                of Transport</v-subheader>
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12">
                            <v-row justify="center">
                                <v-col cols="12" sm="3" class="my-0 py-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>directions_boat</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Sea
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isSea"></v-switch>

                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="my-0 py-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>flight</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Air
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isAir"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="my-0 py-0">
                                    <v-list-item style="border-right: 0.5px solid grey">
                                        <v-list-item-icon>
                                            <v-icon>train</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Rail
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isRail"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                                <v-col cols="12" sm="3" class="my-0 py-0">
                                    <v-list-item>
                                        <v-list-item-icon>
                                            <v-icon>local_shipping</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Road
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-switch v-model="item.isRoad"></v-switch>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-else-if="chargeTab == 1">
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                            <v-list dense subheader>
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Origin Regions
                                </v-subheader>
                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1" v-if="!showOnlySelectedOriginRegion"
                                        @click="showOnlySelectedOriginRegion = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedOriginRegion"
                                        @click="showOnlySelectedOriginRegion = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip outlined color="blue" v-if="!item.allOriginRegion" small
                                        @click="(item.allOriginRegion = true), (selectedOriginRegions = [])">Select
                                        All</v-chip>
                                    <v-chip outlined color="danger" v-else small
                                        @click="(item.allOriginRegion = false), (selectedOriginRegions = [])">Deselect
                                        All</v-chip>
                                </v-subheader>
                                <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                                    v-model="searchOriginRegion" clearable></v-text-field>
                                <v-list dense style="max-height: 35vh; overflow-y: auto" :key="countryKey">
                                    <v-list-item v-for="region in filterOriginRegions" :key="region.id">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ region.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="region.countryRegions">
                                                {{ region.countryRegions.length }} Countries
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action :key="countryKey">
                                            <v-icon v-if="item.allOriginRegion" color="success">check</v-icon>
                                            <v-checkbox v-if="!item.allOriginRegion" v-model="selectedOriginRegions"
                                                color="primary" :value="region.id"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterOriginRegions && filterOriginRegions.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No regions found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-list subheader dense :key="countryKey">
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Origin
                                    Countries
                                </v-subheader>

                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedOriginCountry" @click="showOnlySelectedOriginCountry = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1" v-if="showOnlySelectedOriginCountry"
                                        @click="showOnlySelectedOriginCountry = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip v-if="!item.allOriginCountry" small outlined color="blue"
                                        @click="(item.allOriginCountry = true), (selectedOriginCountries = [])">Select All
                                        Countries
                                    </v-chip>
                                    <v-chip v-else small outlined color="danger"
                                        @click="(item.allOriginCountry = false), (selectedOriginCountries = [])">Deselect
                                        All
                                        Countries</v-chip>
                                </v-subheader>
                                <v-row>
                                    <v-col cols="8" class="mx-0 px-0">
                                        <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense
                                            label="Search" v-model="searchOriginCountry" clearable></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="mx-0 px-0">
                                        <v-autocomplete clearable dense prepend-inner-icon="filter_alt" label="Filter"
                                            outlined class="mt-2" v-model="filterOriginRegion" :items="regions"
                                            item-text="name" item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                                    <v-list-item style="height: 25px" v-for="country in filterOriginCountries"
                                        :key="country.id">
                                        <v-list-item-avatar>
                                            <v-img contain v-if="country.iso2"
                                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ country.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ country.regionCode }}
                                                <span v-if="originRegionSelected(country.regionCode)"
                                                    style="color: var(--v-success-base)"><i>(Region Selected)</i></span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-if="!item.allOriginCountry" v-model="selectedOriginCountries"
                                                color="primary" :value="country.id"></v-checkbox>
                                            <v-icon v-else color="success">check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterOriginCountries && filterOriginCountries.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No countries found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-text v-else-if="chargeTab == 2">
                    <v-row justify="center">
                        <v-col cols="12" sm="12" md="6">
                            <v-list dense subheader>
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Destination
                                    Regions
                                </v-subheader>
                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedDestinationRegion"
                                        @click="showOnlySelectedDestinationRegion = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1"
                                        v-if="showOnlySelectedDestinationRegion"
                                        @click="showOnlySelectedDestinationRegion = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip outlined color="blue" v-if="!item.allDestinationRegion" small
                                        @click="(item.allDestinationRegion = true), (selectedDestinationRegions = [])">Select
                                        All</v-chip>
                                    <v-chip outlined color="danger" v-else small
                                        @click="(item.allDestinationRegion = false), (selectedDestinationRegions = [])">Deselect
                                        All</v-chip>
                                </v-subheader>
                                <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense label="Search"
                                    v-model="searchDestinationRegion" clearable></v-text-field>
                                <v-list dense style="max-height: 35vh; overflow-y: auto" :key="countryKey">
                                    <v-list-item v-for="region in filterDestinationRegions" :key="region.id">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ region.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle style="font-size: 12px" v-if="region.countryRegions">
                                                {{ region.countryRegions.length }} Countries
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action :key="countryKey">
                                            <v-icon v-if="item.allDestinationRegion" color="success">check</v-icon>
                                            <v-checkbox v-if="!item.allDestinationRegion"
                                                v-model="selectedDestinationRegions" color="primary"
                                                :value="region.id"></v-checkbox>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item v-if="filterDestinationRegions && filterDestinationRegions.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No regions found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                            <v-list subheader dense :key="countryKey">
                                <v-subheader style="background: var(--v-component-base); font-size: 16px">Destination
                                    Countries
                                </v-subheader>

                                <v-subheader>
                                    <v-spacer></v-spacer>
                                    <v-chip small outlined color="success" class="mr-1"
                                        v-if="!showOnlySelectedDestinationCountry"
                                        @click="showOnlySelectedDestinationCountry = true">
                                        <v-icon right class="mr-1" small>check</v-icon>
                                        Show Selected</v-chip>
                                    <v-chip small outlined color="primary" class="mr-1"
                                        v-if="showOnlySelectedDestinationCountry"
                                        @click="showOnlySelectedDestinationCountry = false">
                                        <v-icon right class="mr-1" small>list</v-icon>
                                        Show All</v-chip>
                                    <v-chip v-if="!item.allDestinationCountry" small outlined color="blue"
                                        @click="(item.allDestinationCountry = true), (selectedDestinationCountries = [])">Select
                                        All
                                        Countries
                                    </v-chip>
                                    <v-chip v-else small outlined color="danger"
                                        @click="(item.allDestinationCountry = false), (selectedDestinationCountries = [])">Deselect
                                        All
                                        Countries</v-chip>
                                </v-subheader>
                                <v-row>
                                    <v-col cols="8" class="mx-0 px-0">
                                        <v-text-field class="mt-2 px-2" prepend-inner-icon="search" outlined dense
                                            label="Search" v-model="searchDestinationCountry" clearable></v-text-field>
                                    </v-col>
                                    <v-col cols="4" class="mx-0 px-0">
                                        <v-autocomplete clearable dense prepend-inner-icon="filter_alt" label="Filter"
                                            outlined class="mt-2" v-model="filterDestinationRegion" :items="regions"
                                            item-text="name" item-value="id"></v-autocomplete>
                                    </v-col>
                                </v-row>
                                <v-list dense style="height: 35vh; overflow-y: auto" class="mt-0 pt-0">
                                    <v-list-item style="height: 25px" v-for="country in filterDestinationCountries"
                                        :key="country.id">
                                        <v-list-item-avatar>
                                            <v-img contain v-if="country.iso2"
                                                :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ country.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ country.regionCode }}
                                                <span v-if="destinationRegionSelected(country.regionCode)"
                                                    style="color: var(--v-success-base)"><i>(Region Selected)</i></span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox v-if="!item.allDestinationCountry"
                                                v-model="selectedDestinationCountries" color="primary"
                                                :value="country.id"></v-checkbox>
                                            <v-icon v-else color="success">check</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="filterDestinationCountries && filterDestinationCountries.length == 0">
                                        <v-list-item-content>
                                            <span style="color: grey">No countries found.</span>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="categoryModal" width="400px" persistent>
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Category
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="red" v-if="categoryItem.id" :loading="deletingCategory"
                        @click="deleteCategory(categoryItem)"><v-icon>delete</v-icon></v-btn>
                    <v-btn text @click="categoryModal = false, categoryItem = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field label="Category*" outlined dense clearable v-model="categoryItem.category"></v-text-field>
                    <v-row class="mt-2" justify="center">
                        <v-btn text :disabled="!categoryItem.category" @click="saveCategory" color="primary"
                            :loading="savingCategory">Save</v-btn>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
        <v-dialog v-model="subcategoryModal" width="400px" persistent>
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title>
                        Manage Subcategory
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon color="red" v-if="subcategoryItem.id" :loading="deletingSubcategory"
                        @click="deleteSubcategory(subcategoryItem)"><v-icon>delete</v-icon></v-btn>
                    <v-btn text @click="subcategoryModal = false, subcategoryItem = {}">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-text-field label="Subcategory*" outlined dense clearable
                        v-model="subcategoryItem.subCategory"></v-text-field>
                    <v-row class="mt-2" justify="center">
                        <v-btn text :disabled="!subcategoryItem.subCategory" @click="saveSubcategory" color="primary"
                            :loading="savingSubcategory">Save</v-btn>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>

        <v-dialog v-model="filterDialog" width="750px" :fullscreen="$vuetify.breakpoint.mobile">
            <v-card>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title> Filter</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn text @click="filterDialog = false">X</v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :items="chargeCategories" clearable v-model="filter.finChargeCategoryId" @change="refreshKey ++"  label="Category*"
                                item-text="category" item-value="id" outlined dense></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" class="my-0 py-0">
                            <v-select :disabled="!filter.finChargeCategoryId" :items="filterChargeSubCategories" @change="refreshKey ++"
                                v-model="filter.finChargeSubCategoryId" clearable label="Subcategory" item-text="subCategory"  item-value="id" outlined dense>
                                
                            </v-select>

                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader>Shipment Type</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>arrow_downward</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Import
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isImport"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>arrow_upward</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Export
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isExport"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader>Load Type</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>widgets</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Full Load (FL)
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.fullContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>widgets</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Less than Load (LL)
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.lessContainerLoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-subheader>Charge Type</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>location_on</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Origin
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isOrigin"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>local_shipping</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Freight
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isFreight"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>where_to_vote</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Destination
                                    </v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-switch v-model="filter.isDestination"></v-switch>

                                </v-list-item-action>
                            </v-list-item>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-subheader>Mode of Transport</v-subheader>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>directions_boat</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Sea
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="filter.isSea"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>flight</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Air
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="filter.isAir"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>train</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Rail
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="filter.isRail"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item style="height: 30px">
                                <v-list-item-icon>
                                    <v-icon>local_shipping</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Road
                                    </v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="filter.isRoad"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

export default {
    data: () => ({
        baseTypes: [
            { name: 'Per Carton', value: 'PerCarton' },
            { name: 'Per Pallet', value: 'PerPallet' },
            { name: 'Per Container', value: 'PerContainer' },
            { name: 'Per Shipment', value: 'PerShipment' },
            { name: 'Per Document', value: 'PerDocument' },
            { name: 'Flat', value: 'Flat' }
        ],
        categoryItem: {},
        categoryModal: false,
        chargeCategories: [],
        chargeItems: [],
        chargeTab: 0,
        codeRules: [
            (v) =>
                (v && v.length <= 5) ||
                "Code can be a maximum of 5 characters",
        ],
        countries: [],
        countryKey: 4000,
        deletingCategory: false,
        deletingSubcategory: false,
        editKey: 3000,
        filterDialog: false,
        filter: {},
        filterOriginRegion: null,
        filterDestinationRegion: null,
        headers: [
            {
                text: 'Action',
                value: 'action',
                sortable: false,
                width: '80px',
                align: 'center'
            },
            {
                text: 'Code',
                value: 'code',
                align: 'left'
            },
            {
                text: 'Name',
                value: 'name',
                align: 'left'
            },

            {
                text: 'Category',
                value: 'finChargeCategory.category',
                align: 'left'
            },
            {
                text: 'Subcategory',
                value: 'finChargeSubCategory.subCategory',
                align: 'left'
            },
            // {
            //     text: 'Base Type',
            //     value: 'baseType',
            //     align: 'left'
            // },
            {
                text: 'Shipment Type',
                value: 'importExport',
                sortable: false,
                align: 'center'
            },
            // {
            //     text: 'Export',
            //     value: 'isExport',
            //     align: 'center'
            // },

            {
                text: 'Charge Type',
                value: 'chargeLocation',
                sortable: false,
                width: '150px',
                align: 'center'
            },

            // {
            //     text: 'Origin',
            //     value: 'isOrigin',
            //     align: 'center'
            // },
            // {
            //     text: 'Freight',
            //     value: 'isFreight',
            //     align: 'center'
            // },
            // {
            //     text: 'Destination',
            //     value: 'isDestination',
            //     align: 'center'
            // },

            {
                text: 'Mode',
                value: 'mode',
                sortable: false,
                width: '180px',
                align: 'center'
            },
            // {
            //     text: 'Sea',
            //     value: 'isSea',
            //     align: 'center'
            // },
            // {
            //     text: 'Air',
            //     value: 'isAir',
            //     align: 'center'
            // },
            // {
            //     text: 'Rail',
            //     value: 'isRail',
            //     align: 'center'
            // }
            // ,
            // {
            //     text: 'Road',
            //     value: 'isRoad',
            //     align: 'center'
            // },
            {
                text: 'Load Type',
                value: 'loadType',
                sortable: false,
                width: '140px',
                align: 'center'
            },

            {
                text: 'Region Specific',
                value: 'region',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Country Specific',
                value: 'country',
                sortable: false,
                align: 'center'
            },
            // {
            //     text: 'Dest. Region',
            //     value: 'destinationRegion',
            //     sortable: false,
            //     align: 'center'
            // },
            // {
            //     text: 'Dest. Country',
            //     value: 'destinationCountry',
            //     sortable: false,
            //     align: 'center'
            // },
        ],
        item: {},
        loadingChargeItems: false,
        modal: false,
        refreshKey: 1000,
        regions: [],
        savingCategory: false,
        savingChargeItem: false,
        savingSubcategory: false,
        search: null,
        searchOriginRegion: null,
        searchDestinationRegion: null,
        selectedOriginRegions: [],
        selectedDestinationRegions: [],

        searchOriginCountry: null,
        searchDestinationCountry: null,
        selectedOriginCountries: [],
        selectedDestinationCountries: [],

        showOnlySelectedOriginRegion: false,
        showOnlySelectedDestinationRegion: false,
        showOnlySelectedOriginCountry: false,
        showOnlySelectedDestinationCountry: false,



        subcategoryItem: {},
        subcategoryModal: false,
        validCodeKey: 5000
    }),
    watch: {
        'item.allOriginRegion': {
            immediate: true,
            handler() {
                this.countryKey++
            }
        },
        'item.allOriginCountry': {
            immediate: true,
            handler() {
                this.countryKey++
            }
        },
        'item.allDestinationRegion': {
            immediate: true,
            handler() {
                this.countryKey++
            }
        },
        'item.allDestinationCountry': {
            immediate: true,
            handler() {
                this.countryKey++
            }
        }
    },
    computed: {
        computeFilterCount() {
            let count = 0
            let keys = Object.keys(this.filter)
            for (let i = 0; i < keys.length; i++) {
                if (this.filter[keys[i]]) {
                    count++
                }
            }
            return count
        },
        chargeSubCategories() {
            let result = []
            if (this.item.finChargeCategoryId) {
                result = this.chargeCategories.find(c => c.id === this.item.finChargeCategoryId).finChargeSubCategories
            }
            return result
        },
        filterChargeSubCategories() {
            let result = []
            if (this.filter.finChargeCategoryId) {
                result = this.chargeCategories.find(c => c.id === this.filter.finChargeCategoryId).finChargeSubCategories
            }
            return result
        },
        filteredChargeItems() {
            let result = this.chargeItems
            if (this.filter) {
                let keys = Object.keys(this.filter)
                let filter = keys.filter(x => this.filter[x])
                result = result.filter(x => filter.every(y => x[y] == this.filter[y]))
            }
            return result
        },
        filterOriginCountries() {
            let result = [];
            let regions = this.regions;
            if (this.filterOriginRegion) {
                regions = regions.filter((x) => x.id == this.filterOriginRegion);
            }
            result = [].concat.apply(
                [],
                regions.map((x) => x.countryRegions)
            );
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            if (this.searchOriginCountry) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchOriginCountry.toLowerCase())
                );
            }
            if (this.showOnlySelectedOriginCountry && !this.item.allOriginCountry) {
                result = result.filter((x) => this.selectedOriginCountries.includes(x.id));
            }
            return result;
        },
        filterDestinationCountries() {
            let result = [];
            let regions = this.regions;
            if (this.filterDestinationRegion) {
                regions = regions.filter((x) => x.id == this.filterDestinationRegion);
            }
            result = [].concat.apply(
                [],
                regions.map((x) => x.countryRegions)
            );
            result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
            if (this.searchDestinationCountry) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchDestinationCountry.toLowerCase())
                );
            }
            if (this.showOnlySelectedDestinationCountry && !this.item.allDestinationCountry) {
                result = result.filter((x) => this.selectedDestinationCountries.includes(x.id));
            }
            return result;
        },
        filterOriginRegions() {
            let result = this.regions;
            if (this.showOnlySelectedOriginRegion && !this.item.allOriginRegion) {
                result = result.filter((x) => this.selectedOriginRegions.includes(x.id));
            }
            if (this.searchOriginRegion) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchOriginRegion.toLowerCase())
                );
            }
            return result;
        },
        filterDestinationRegions() {
            let result = this.regions;
            if (this.showOnlySelectedDestinationRegion && !this.item.allDestinationRegion) {
                result = result.filter((x) => this.selectedDestinationRegions.includes(x.id));
            }
            if (this.searchDestinationRegion) {
                result = result.filter((x) =>
                    x.name.toLowerCase().includes(this.searchDestinationRegion.toLowerCase())
                );
            }
            return result;
        },
        
    },
    async created() {
        this.getFinanceCategories()
        this.getChargeItems()
        this.regions = await this.$API.getRegions();
        this.countries = [].concat.apply(
            [],
            this.regions.map((x) => x.countryRegions)
        );
    },
    methods: {
        addCategory() {
            this.categoryItem = {}
            this.categoryModal = true
        },
        addSubCategory() {
            this.subcategoryItem = {
                finChargeCategoryId: this.item.finChargeCategoryId
            }
            this.subcategoryModal = true
        },
        addItem() {
            this.item = {
                allOriginRegion: true,
                allOriginCountry: true,
                allDestinationRegion: true,
                allDestinationCountry: true,
                finChargeItemAreas: []
            }
            this.item.modalType = 'Add'
            this.selectedOriginRegions = []
            this.selectedDestinationRegions = []
            this.selectedOriginCountries = []
            this.selectedDestinationCountries = []
            this.modal = true
        },
        deleteItem(item) {
            this.loadingChargeItems = true
            this.$confirm("Are you sure you want to delete this charge item?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    if (item.id) {
                        await this.$API.updateFinanceChargeItem({
                            id: item.id,
                            isActive: false,
                            isDeleted: true,
                        });
                    }
                    let index = this.chargeItems.indexOf(item)
                    this.chargeItems.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.loadingChargeItems = false
                })
                .catch(() => {
                    this.loadingChargeItems = false
                });
        },
        deleteCategory(item) {
            this.deletingCategory = true
            this.$confirm("Are you sure you want to delete this category?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    if (item.id) {
                        await this.$API.updateFinanceCategory({
                            id: item.id,
                            isActive: false,
                            isDeleted: true,
                        });
                    }
                    let index = this.chargeCategories.findIndex(x => x.id == item.finChargeCategoryId)
                    this.chargeCategories.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.categoryModal = false
                    this.categoryItem = {}
                    this.deletingCategory = false
                    this.item.finChargeCategoryId = null
                })
                .catch(() => {
                    this.deletingCategory = false
                    // this.$message({
                    //   type: "info",
                    //   message: "Delete canceled",
                    // });
                });
        },
        deleteSubcategory(item) {
            this.deletingSubcategory = true
            this.$confirm("Are you sure you want to delete this subcategory?", "Confirm", {
                center: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                type: "plain",
            })
                .then(async () => {
                    if (item.id) {
                        await this.$API.updateFinanceSubCategory({
                            id: item.id,
                            isActive: false,
                            isDeleted: true,
                        });
                    }
                    let categoryItem = this.chargeCategories.find(x => x.id == item.finChargeCategoryId)
                    let index = categoryItem.finChargeSubCategories.indexOf(item)
                    categoryItem.finChargeSubCategories.splice(index, 1);
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                    this.subcategoryModal = false
                    this.subcategoryItem = {}
                    this.deletingSubcategory = false
                    this.item.finChargeSubCategoryId = null
                })
                .catch(() => {
                    this.deletingSubcategory = false
                    // this.$message({
                    //   type: "info",
                    //   message: "Delete canceled",
                    // });
                });
        },
        editCategory(id) {
            this.categoryItem = this.chargeCategories.find(x => x.id == id)
            this.categoryModal = true
        },
        editSubcategory(id) {
            let item = this.chargeCategories.find(x => x.id == this.item.finChargeCategoryId)
            this.subcategoryItem = item.finChargeSubCategories.find(x => x.id == id)
            this.subcategoryModal = true
        },
        editItem(item) {
            this.item = item
            this.item.modalType = 'Edit'
            this.selectedOriginRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.regionId).map(x => x.regionId)
            this.selectedDestinationRegions = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.regionId).map(x => x.regionId)
            this.selectedOriginCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.countryRegionId).map(x => x.countryRegionId)
            this.selectedDestinationCountries = this.item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.countryRegionId).map(x => x.countryRegionId)
            this.modal = true
        },
        formatBaseType(type){
            let result = null
            if(type){
                result = this.baseTypes.find(x=>x.value == type)
                result = result.name
            } 
            return result
        },
        formattedCountries(type, item) {
            let countries = item.finChargeItemAreas.filter(x => x.type == type && x.countryRegionId).map(x => x.countryRegionId)
            return this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length > 0 ? this.countries.filter(x => countries.includes(x.id)).map(x => x.name).length : null
        },
        formattedRegions(type, item) {
            let regions = item.finChargeItemAreas.filter(x => x.type == type && x.regionId).map(x => x.regionId)
            return this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length > 0 ? this.regions.filter(x => regions.includes(x.id)).map(x => x.name).length : null
        },
        async getChargeItems() {
            this.loadingChargeItems = true
            this.chargeItems = await this.$API.getAdminFinanceChargeItems()
            this.loadingChargeItems = false
        },
        async getFinanceCategories() {
            try {
                this.chargeCategories = await this.$API.getAllFinanceCategories()
            } catch (e) {
                this.$message.error(e.message)
            }
        },
        originRegionSelected(code) {
            let find = this.regions.find((x) => x.code == code);
            return find ? this.selectedOriginRegions.includes(find.id) : false;
        },
        destinationRegionSelected(code) {
            let find = this.regions.find((x) => x.code == code);
            return find ? this.selectedDestinationRegions.includes(find.id) : false;
        },
        async saveCategory() {
            this.savingCategory = true
            try {
                if (!this.categoryItem.id) {
                    await this.$API.createFinanceCategory(this.categoryItem)
                } else {
                    await this.$API.updateFinanceCategory(this.categoryItem)
                }
                this.$message.success('Category saved successfully')
                this.getFinanceCategories()
                this.categoryModal = false
                this.categoryItem = {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.savingCategory = false
            }
        },
        async saveChargeItem() {
            this.savingChargeItem = true
            try {
                let result
                if (!this.item.id) {
                    let result = await this.$API.createAdminFinanceChargeItem(this.item)
                    this.chargeItems.push(result)
                    this.item.id = result.id
                } else {
                    let result = await this.$API.updateAdminFinanceChargeItem(this.item)
                    let index = this.chargeItems.findIndex(x => x.id == this.item.id)
                    this.chargeItems[index] = result
                }
                await this.saveCountryRegions(this.item)
                this.$message.success('Charge Item saved successfully')

                this.modal = false
                this.item = {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.savingChargeItem = false
                this.getChargeItems()
                this.refreshKey++

            }
        },
        async saveCountryRegions(item) {
            let existingOriginRegions = item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.regionId)
            let existingDestinationRegions = item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.regionId)
            let existingOriginCountries = item.finChargeItemAreas.filter(x => x.type == 'Origin' && x.countryRegionId)
            let existingDestinationCountries = item.finChargeItemAreas.filter(x => x.type == 'Destination' && x.countryRegionId)

            let originRegionsToAdd = this.selectedOriginRegions.filter(x => !existingOriginRegions.map(y => y.regionId).includes(x))
            let destinationRegionsToAdd = this.selectedDestinationRegions.filter(x => !existingDestinationRegions.map(y => y.regionId).includes(x))
            let originCountriesToAdd = this.selectedOriginCountries.filter(x => !existingOriginCountries.map(y => y.countryRegionId).includes(x))
            let destinationCountriesToAdd = this.selectedDestinationCountries.filter(x => !existingDestinationCountries.map(y => y.countryRegionId).includes(x))

            let create = [
                ...originRegionsToAdd.map(x => ({ type: 'Origin', regionId: x, finChargeItemId: item.id })),
                ...destinationRegionsToAdd.map(x => ({ type: 'Destination', regionId: x, finChargeItemId: item.id })),
                ...originCountriesToAdd.map(x => ({ type: 'Origin', countryRegionId: x, finChargeItemId: item.id })),
                ...destinationCountriesToAdd.map(x => ({ type: 'Destination', countryRegionId: x, finChargeItemId: item.id }))
            ]
            let originRegionsToDelete = existingOriginRegions.filter(x => !this.selectedOriginRegions.includes(x.regionId))
            let destinationRegionsToDelete = existingDestinationRegions.filter(x => !this.selectedDestinationRegions.includes(x.regionId))
            let originCountriesToDelete = existingOriginCountries.filter(x => !this.selectedOriginCountries.includes(x.countryRegionId))
            let destinationCountriesToDelete = existingDestinationCountries.filter(x => !this.selectedDestinationCountries.includes(x.countryRegionId))
            let deleteIds = [
                ...originRegionsToDelete.map(x => x.id),
                ...destinationRegionsToDelete.map(x => x.id),
                ...originCountriesToDelete.map(x => x.id),
                ...destinationCountriesToDelete.map(x => x.id)
            ]
            deleteIds = deleteIds.map(x => ({ id: x, isActive: false, isDeleted: true }))
            let batch = [...create, ...deleteIds]
            await this.$API.createFinanceChargeItemArea({ batch })
        },
        async saveSubcategory() {
            this.savingSubcategory = true
            try {
                if (!this.subcategoryItem.id) {
                    await this.$API.createFinanceSubCategory(this.subcategoryItem)
                    // let findCategory = this.chargeCategories.find(x => x.id == this.subcategoryItem.finChargeCategoryId)
                    // findCategory.finChargeSubCategories.push(result)
                } else {
                    await this.$API.updateFinanceSubCategory(this.subcategoryItem)
                    // let findCategory = this.chargeCategories.find(x => x.id == this.subcategoryItem.finChargeCategoryId)
                    // let index = findCategory.finChargeSubCategories.findIndex(x => x.id == this.subcategoryItem.id)
                    // findCategory.finChargeSubCategories[index] = result
                }
                this.getFinanceCategories()

                this.$message.success('Subcategory saved successfully')
                this.subcategoryModal = false
                this.subcategoryItem = {}
            } catch (e) {
                this.$message.error(e.message)
            } finally {
                this.savingSubcategory = false
            }
        },
        validCode(){
          if(this.item.code && this.item.code.length >=5 && !this.chargeItems.find(x=>x.code == this.item.code && x.id != this.item.id)){
            return true
          } else if(!this.item.code){
            return true
          } else {
            return false
          }
        }
    }
}
</script>